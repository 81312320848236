import { RuleActivityAdditionalType, SpecialRuleType } from '../Models';

type EnumDictionary<T extends string | symbol | number, U> = {
    // eslint-disable-next-line no-unused-vars
    [K in T]: U;
};

export const SpecialRuleTypeLabels: EnumDictionary<SpecialRuleType, string> = {
    [SpecialRuleType.BackPositioning]: 'Back Positioning',
    [SpecialRuleType.BlockHours]: 'Block Hours',
    [SpecialRuleType.HasFlightOrPos]: 'Has Flight or POS',
    [SpecialRuleType.NonFlightRotation]: 'Has Rotation without Flight',
    [SpecialRuleType.ConsecutiveLays]: 'Consecutive Lays',
    [SpecialRuleType.FreelancerRotationDays]: 'Freelance Rotation Days',
    [SpecialRuleType.LocationChange]: 'Location change',
    [SpecialRuleType.FlightNumber]: 'Flight Number',
    [SpecialRuleType.OverNightHotel]: 'Layover sequence',
    [SpecialRuleType.MinZrhTime]: 'Minimal Time not in ZRH',
    [SpecialRuleType.HourlyShift]: 'Hourly Shift',
    [SpecialRuleType.None]: '',
    [SpecialRuleType.TrainingChecker]: 'Checker',
    [SpecialRuleType.Weekend]: 'Weekend Duty',
    [SpecialRuleType.WeekendHoliday]: 'Weekend or Holiday Duty',
    [SpecialRuleType.AgeAddition]: 'Age Addition',
    [SpecialRuleType.CounterBased]: 'Counter',
    [SpecialRuleType.Location]: 'Specific location'
};

export const SpecialRuleTypeDescriptions: EnumDictionary<SpecialRuleType, string> = {
    [SpecialRuleType.BackPositioning]: 'The rule is applied if crew member is positioned by the same aircraft.',
    [SpecialRuleType.BlockHours]: 'The rule is applied if a crew member has Block Hours, output - number of hours',
    [SpecialRuleType.HasFlightOrPos]: 'The rule is applied if a crew member has flight or POS this day.',
    [SpecialRuleType.ConsecutiveLays]: 'The rule is applied if crew member has consecutive Lays INSIDE rotation AND/BND inside flight rotation is accepted.',
    [SpecialRuleType.LocationChange]: 'The rule is applied if crew member changes location, use special arguments for defining location and time, example: DEP,ZRH,00:00-20:00,75;ARR,ZRH,02:00-04:00,30',
    [SpecialRuleType.FlightNumber]: 'The rule is applied if crew member is performing some flight activity with specific flight number, example: Day:500-520;Sector:9000-9100',
    [SpecialRuleType.FreelancerRotationDays]: 'Special rule for rotation days calculation for Freelancers',
    [SpecialRuleType.OverNightHotel]: 'The rule is applied if crew member has > 10h in specific location (hotel). Accepted arguments: comma separated 3 letters airport code. Examples: "PJU,VRA". Use "-" for negative rule ex: "-ZRH"',
    [SpecialRuleType.MinZrhTime]: 'The rule is applied if crew member stays not in ZRH. Accepted argument: "0-24" or "24-".',
    [SpecialRuleType.HourlyShift]: 'The rule is applied if crew member has duty in specified period (example: "20:00-22:00" for UTC and "ZHR;20:00-22:00" for ZRH).',
    [SpecialRuleType.None]: '',
    [SpecialRuleType.TrainingChecker]: 'The rule is applied if crew member is flight line checker. Accepted argument: [LH|SH prefix:]<function-qualification>. Examples: "LIFUS/TC", "LIFUS/TRI", "LH:LIFUS/TC,LIFUS/TRI".',
    [SpecialRuleType.Weekend]: 'The rule is applied if crew member has weekend duty.',
    [SpecialRuleType.WeekendHoliday]: 'The rule is applied if crew member has weekend or holiday duty.',
    [SpecialRuleType.CounterBased]: 'The rule is applied if counter (defined in another rule) has value in the specified range. Example: "$ct_counter=[9-14] or $ct_counter=[15-]"',
    [SpecialRuleType.Location]: 'The rule is applied when person is in specified location',
    [SpecialRuleType.AgeAddition]: 'Adds specified amount based on configuration. Accepted argument: "18-29:8.3;30-49:10.6;50-99:13"',
    [SpecialRuleType.NonFlightRotation]: 'The rule is applied when has rotation without flight'
};

export const ActivityAdditionalTypeLabels: EnumDictionary<RuleActivityAdditionalType, string> = {
    [RuleActivityAdditionalType.SimulationMemberType]: 'SIM Member Type',
    [RuleActivityAdditionalType.SimulationInstructorRequirements]: 'SIM Instructor requirement',
    [RuleActivityAdditionalType.SimulationLpl4]: 'SIM LPL-4',
    [RuleActivityAdditionalType.Not]: 'Not',
    [RuleActivityAdditionalType.SimulationType]: 'Simulation Type'
};
